@use 'vars';

.tree-node-icon {
    @include vars.themifyModule() {
        color: vars.color('typo-primary');
    }
}

:global {
    .document-data-tree {
        @include vars.themifyModule() {
            color: vars.color('typo-primary');
        }
        // list
        .document-tree-list-holder-inner {
            align-items: flex-start;
        }

        // node
        .document-tree-treenode {
            display: flex;
            position: relative;

            @include vars.themifyModule() {
                margin: vars.marginStep(0.5) 0;
            }
            word-break: break-word;
        }

        .document-tree-treenode-switcher-open,
        .document-tree-treenode-switcher-close {
            @include vars.themifyModule() {
                margin-left: vars.marginStep(-2);
            }
        }

        // node indent
        .document-tree-indent {
            flex-shrink: 0;
            display: inline-block;
            vertical-align: bottom;
            height: 0;
        }
        .document-tree-indent-unit {
            width: 38px;
            display: inline-block;
        }

        // node expand/collapse switch
        .document-tree-switcher {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            z-index: 1;
        }

        .document-tree-switcher-noop {
            cursor: default;
        }
    }
}
